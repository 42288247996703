import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../../../components/AccountForm';
import { AuthRepositoryType } from '../../../../contracts/repositories';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ChangePasswordView = class ChangePasswordView extends Vue {
    constructor() {
        super(...arguments);
        this.formName = 'changePasswordForm';
        this.successMessage = '';
        /**
         * Object that collects User input.
         */
        this.formData = {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        };
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
    }
    /**
     * Handles the `@cancel` event on the `<AccountForm>` component.
     */
    onCancel() {
        this.$router.push({ name: 'profile.my-data' });
    }
    /**
     * Handles the `@submit` event on the `<AccountForm>` component.
     */
    onSubmit() {
        this.isActing = true;
        this.successMessage = '';
        this.authRepository.changePassword(this.formData)
            .then(() => {
            this.successMessage = `${this.$t('modules.profile.views.change-password.success')}`;
        })
            .catch(error => this.$logger(error, 'error'))
            .finally(() => {
            this.isActing = false;
        });
    }
};
__decorate([
    Inject(AuthRepositoryType)
], ChangePasswordView.prototype, "authRepository", void 0);
ChangePasswordView = __decorate([
    Component({ name: 'ChangePasswordView', components: { AccountForm, Screen } })
], ChangePasswordView);
export { ChangePasswordView };
export default ChangePasswordView;
